import React, { useEffect, useState } from 'react';
import { fabric } from 'fabric';
import './App.css';
import {UploadCropOptionDispatcher} from './components/Crop'
import Cat from './image/cat.jpeg'


fabric.Object.prototype.objID=undefined;
fabric.Object.prototype.objType=undefined;
fabric.Object.prototype.isNewCrop= undefined;
fabric.Object.prototype.custtype= undefined;
fabric.Object.prototype.picAreaScale=undefined;
fabric.Object.prototype.oldOriginX=undefined;
fabric.Object.prototype.oldOriginY=undefined;
fabric.Object.prototype.oldLeft=undefined;
fabric.Object.prototype.oldTop= undefined;
fabric.Object.prototype.oldScaleX= undefined;
fabric.Object.prototype.oldScaleY=undefined;
fabric.Object.prototype.oldWidth=undefined;
fabric.Object.prototype.oldHeight= undefined;
fabric.Object.prototype.allowSnapping=undefined;
fabric.Object.prototype.checkIntersection=undefined;
fabric.Object.prototype.objects=undefined;
fabric.Object.prototype.sourcePath=undefined;


const img = document.createElement('img')
img.src =
    'data:image/svg+xml;base64,PHN2ZyBpZD0iaWNfUm90YXRlLUVsZW1lbnQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjIwIiBoZWlnaHQ9IjIwIiB2aWV3Qm94PSIwIDAgMjAgMjAiPg0KICA8cGF0aCBpZD0iUGF0aF8xMzE4IiBkYXRhLW5hbWU9IlBhdGggMTMxOCIgZD0iTTEwLjAyLDIwYTMuMywzLjMsMCwwLDEtMy4zLTMuM1YzLjNhMy4zLDMuMywwLDAsMSw2LjYsMFYxNi43YTMuMywzLjMsMCwwLDEtMy4zLDMuM1ptMC0xOC40MzdBMS43NCwxLjc0LDAsMCwwLDguMjgxLDMuM1YxNi43YTEuNzM4LDEuNzM4LDAsMSwwLDMuNDc3LDBWMy4zQTEuNzQsMS43NCwwLDAsMCwxMC4wMiwxLjU2MlpNNC4wMjgsMTcuNWEuNzc4Ljc3OCwwLDAsMS0uNTA2LS4xODZBMTAuMDE1LDEwLjAxNSwwLDAsMSwxLDUuMzMyLjc4MS43ODEsMCwwLDEsMi40LDYuMDEzYTguNDUyLDguNDUyLDAsMCwwLDIuMTMsMTAuMTExQS43ODEuNzgxLDAsMCwxLDQuMDI4LDE3LjVaTTUuMTU2LDYuMDU1VjQuMThBMi4xMzUsMi4xMzUsMCwwLDAsMy4wMTksMi4wMzJIMS4xNzJhLjc4MS43ODEsMCwwLDAsMCwxLjU2MkgzLjAxNWEuNTcyLjU3MiwwLDAsMSwuNTc5LjU3OVY2LjA1NWEuNzgxLjc4MSwwLDAsMCwxLjU2MywwWk0xOSwxNC42NjhBMTAuMDE1LDEwLjAxNSwwLDAsMCwxNi40NzgsMi42ODZhLjc4MS43ODEsMCwwLDAtMS4wMTMsMS4xOUE4LjQ1Miw4LjQ1MiwwLDAsMSwxNy42LDEzLjk4Ny43ODEuNzgxLDAsMSwwLDE5LDE0LjY2OFptLjYwNywyLjUyYS43ODEuNzgxLDAsMCwwLS43ODEtLjc4MkgxNi45ODJhLjU3Mi41NzIsMCwwLDEtLjU3NS0uNTc5VjEzLjk0NWEuNzgxLjc4MSwwLDAsMC0xLjU2MywwVjE1LjgyYTIuMTM1LDIuMTM1LDAsMCwwLDIuMTM4LDIuMTQ3aDEuODQ3YS43ODEuNzgxLDAsMCwwLC43ODEtLjc4MVoiLz4NCjwvc3ZnPg0K'

const imgRectangleHorizontal = document.createElement('img')
imgRectangleHorizontal.src =
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzIiIGhlaWdodD0iMTEiIHZpZXdCb3g9IjAgMCAzMiAxMSI+CiAgPGRlZnM+CiAgICA8ZmlsdGVyIGlkPSJSZWN0YW5nbGVfMTI3MiIgeD0iMCIgeT0iMCIgd2lkdGg9IjMyIiBoZWlnaHQ9IjExIiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiPgogICAgICA8ZmVPZmZzZXQgaW5wdXQ9IlNvdXJjZUFscGhhIi8+CiAgICAgIDxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEiIHJlc3VsdD0iYmx1ciIvPgogICAgICA8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwLjE2MSIvPgogICAgICA8ZmVDb21wb3NpdGUgb3BlcmF0b3I9ImluIiBpbjI9ImJsdXIiLz4KICAgICAgPGZlQ29tcG9zaXRlIGluPSJTb3VyY2VHcmFwaGljIi8+CiAgICA8L2ZpbHRlcj4KICA8L2RlZnM+CiAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwgMCwgMCwgMSwgMCwgMCkiIGZpbHRlcj0idXJsKCNSZWN0YW5nbGVfMTI3MikiPgogICAgPGcgaWQ9IlJlY3RhbmdsZV8xMjcyLTIiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDEyNzIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDMgMykiIGZpbGw9IiNmZmYiIHN0cm9rZT0iI2RhZGFkZCIgc3Ryb2tlLXdpZHRoPSIwLjUiPgogICAgICA8cmVjdCB3aWR0aD0iMjYiIGhlaWdodD0iNSIgcng9IjIuNSIgc3Ryb2tlPSJub25lIi8+CiAgICAgIDxyZWN0IHg9IjAuMjUiIHk9IjAuMjUiIHdpZHRoPSIyNS41IiBoZWlnaHQ9IjQuNSIgcng9IjIuMjUiIGZpbGw9Im5vbmUiLz4KICAgIDwvZz4KICA8L2c+Cjwvc3ZnPgo='

const imgRectangleVertical = document.createElement('img')
imgRectangleVertical.src =
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMTEiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAxMSAzMiI+CiAgPGRlZnM+CiAgICA8ZmlsdGVyIGlkPSJSZWN0YW5nbGVfMTI3NCIgeD0iMCIgeT0iMCIgd2lkdGg9IjExIiBoZWlnaHQ9IjMyIiBmaWx0ZXJVbml0cz0idXNlclNwYWNlT25Vc2UiPgogICAgICA8ZmVPZmZzZXQgaW5wdXQ9IlNvdXJjZUFscGhhIi8+CiAgICAgIDxmZUdhdXNzaWFuQmx1ciBzdGREZXZpYXRpb249IjEiIHJlc3VsdD0iYmx1ciIvPgogICAgICA8ZmVGbG9vZCBmbG9vZC1vcGFjaXR5PSIwLjE2MSIvPgogICAgICA8ZmVDb21wb3NpdGUgb3BlcmF0b3I9ImluIiBpbjI9ImJsdXIiLz4KICAgICAgPGZlQ29tcG9zaXRlIGluPSJTb3VyY2VHcmFwaGljIi8+CiAgICA8L2ZpbHRlcj4KICA8L2RlZnM+CiAgPGcgdHJhbnNmb3JtPSJtYXRyaXgoMSwgMCwgMCwgMSwgMCwgMCkiIGZpbHRlcj0idXJsKCNSZWN0YW5nbGVfMTI3NCkiPgogICAgPGcgaWQ9IlJlY3RhbmdsZV8xMjc0LTIiIGRhdGEtbmFtZT0iUmVjdGFuZ2xlIDEyNzQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDggMykgcm90YXRlKDkwKSIgZmlsbD0iI2ZmZiIgc3Ryb2tlPSIjZGFkYWRkIiBzdHJva2Utd2lkdGg9IjAuNSI+CiAgICAgIDxyZWN0IHdpZHRoPSIyNiIgaGVpZ2h0PSI1IiByeD0iMi41IiBzdHJva2U9Im5vbmUiLz4KICAgICAgPHJlY3QgeD0iMC4yNSIgeT0iMC4yNSIgd2lkdGg9IjI1LjUiIGhlaWdodD0iNC41IiByeD0iMi4yNSIgZmlsbD0ibm9uZSIvPgogICAgPC9nPgogIDwvZz4KPC9zdmc+Cg=='

const imgCircleCorners = document.createElement('img')
imgCircleCorners.src =
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNDEuNTYxIiBoZWlnaHQ9IjQxLjU2MSIgdmlld0JveD0iMCAwIDQxLjU2MSA0MS41NjEiPgogIDxkZWZzPgogICAgPGZpbHRlciBpZD0iRWxsaXBzZV8zNiIgeD0iMCIgeT0iMCIgd2lkdGg9IjQxLjU2MSIgaGVpZ2h0PSI0MS41NjEiIGZpbHRlclVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+CiAgICAgIDxmZU9mZnNldCBpbnB1dD0iU291cmNlQWxwaGEiLz4KICAgICAgPGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMS41IiByZXN1bHQ9ImJsdXIiLz4KICAgICAgPGZlRmxvb2QgZmxvb2Qtb3BhY2l0eT0iMC4xNjEiLz4KICAgICAgPGZlQ29tcG9zaXRlIG9wZXJhdG9yPSJpbiIgaW4yPSJibHVyIi8+CiAgICAgIDxmZUNvbXBvc2l0ZSBpbj0iU291cmNlR3JhcGhpYyIvPgogICAgPC9maWx0ZXI+CiAgPC9kZWZzPgogIDxnIHRyYW5zZm9ybT0ibWF0cml4KDEsIDAsIDAsIDEsIDAsIDApIiBmaWx0ZXI9InVybCgjRWxsaXBzZV8zNikiPgogICAgPGcgaWQ9IkVsbGlwc2VfMzYtMiIgZGF0YS1uYW1lPSJFbGxpcHNlIDM2IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg0LjUgNC41KSIgZmlsbD0iI2ZmZiIgc3Ryb2tlPSIjZGFkYWRkIiBzdHJva2Utd2lkdGg9IjEiPgogICAgICA8Y2lyY2xlIGN4PSIxNi4yODEiIGN5PSIxNi4yODEiIHI9IjE2LjI4MSIgc3Ryb2tlPSJub25lIi8+CiAgICAgIDxjaXJjbGUgY3g9IjE2LjI4MSIgY3k9IjE2LjI4MSIgcj0iMTUuNzgxIiBmaWxsPSJub25lIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K'


function renderIcon(
  ctx,
  left,
  top,
  styleOverride,
  fabricObject,
) {
  const size = 24
  ctx.save()
  ctx.translate(left, top)
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
  ctx.drawImage(img, -size / 2, -size / 2, size, size)
  ctx.restore()
}

function cornerIcon(
  ctx,
  left,
  top,
  styleOverride,
  fabricObject,
) {
  const size = 14
  ctx.save()
  ctx.translate(left, top)
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
  ctx.drawImage(imgCircleCorners, -size / 2, -size / 2, size, size)
  ctx.restore()
}

function horizontalBorderIcon(
  ctx,
  left,
  top,
  styleOverride,
  fabricObject,
) {
  const size = 24
  ctx.save()
  ctx.translate(left, top)
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
  ctx.drawImage(imgRectangleHorizontal, -size / 2, -size / 2, size, size)
  ctx.restore()
}

function verticalBorderIcon(
  ctx,
  left,
  top,
  styleOverride,
  fabricObject,
) {
  const size = 24
  ctx.save()
  ctx.translate(left, top)
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle))
  ctx.drawImage(imgRectangleVertical, -size / 2, -size / 2, size, size)
  ctx.restore()
}


 const App=()=>{



  const [can,setCan] = useState();
  const [cropObject,setCropObject]=useState();
let mtr=fabric.Object.prototype.controls.mtr.render;
let tr=fabric.Object.prototype.controls.tr.render
let br=fabric.Object.prototype.controls.br.render 
let bl=fabric.Object.prototype.controls.bl.render
let tl=fabric.Object.prototype.controls.tl.render
let mb=fabric.Object.prototype.controls.mb.render 
let mt=fabric.Object.prototype.controls.mt.render 
let mr=fabric.Object.prototype.controls.mr.render
let ml=fabric.Object.prototype.controls.ml.render



  useEffect(() => {
    const canElement = document.getElementById('canvasElement');
    const canvas = new fabric.Canvas(canElement);
    setCan(canvas);

  },[]);

  useEffect(()=>{
    let canvas=can;
    let cropObj = new UploadCropOptionDispatcher(canvas)
    setCropObject(cropObj);

  // canvas?.off('object:modified',eveObjectModified)
  // canvas?.on('object:modified',eveObjectModified)

  canvas?.off('object:scaling',eveObjectScaling)
  canvas?.on('object:scaling',eveObjectScaling)

  canvas?.off('object:moving',eveObjectMoving)
  canvas?.on('object:moving',eveObjectMoving)

  canvas?.off('selection:cleared',eveSelectionCleared)
  canvas?.on('selection:cleared',eveSelectionCleared)


  canvas?.off('selection:updated',eveSelectionUpdated)
  canvas?.on('selection:updated',eveSelectionUpdated)

  canvas?.off('object:added',eveObjectAdded)
  canvas?.on('object:added',eveObjectAdded)

  canvas?.off('selection:created',eveSelectionCreated)
  canvas?.on('selection:created',eveSelectionCreated)


  },[can])


  const checkCropStatus=(target)=>{
    let selectedObject=target;
    if(selectedObject.custtype==="picArea" || selectedObject.id==="Layer_1"){
fabric.Object.prototype.controls.mtr.render=renderIcon
fabric.Object.prototype.controls.tr.render =cornerIcon
fabric.Object.prototype.controls.br.render =cornerIcon
fabric.Object.prototype.controls.bl.render =cornerIcon
fabric.Object.prototype.controls.tl.render =cornerIcon
fabric.Object.prototype.controls.mb.render =horizontalBorderIcon
fabric.Object.prototype.controls.mt.render =horizontalBorderIcon
fabric.Object.prototype.controls.mr.render =verticalBorderIcon
fabric.Object.prototype.controls.ml.render =verticalBorderIcon
    }
    else if(selectedObject.custtype!=="picArea"){
fabric.Object.prototype.controls.mtr.render=mtr
fabric.Object.prototype.controls.tr.render =tr
fabric.Object.prototype.controls.br.render =br
fabric.Object.prototype.controls.bl.render =bl
fabric.Object.prototype.controls.tl.render =tl
fabric.Object.prototype.controls.mb.render =mb
fabric.Object.prototype.controls.mt.render =mt
fabric.Object.prototype.controls.mr.render =mr
fabric.Object.prototype.controls.ml.render =ml
    }
  }

  const eveObjectMoving=(e)=>{
    const imgObject = can?.getObjects().find((item) => { return true === item.isNewCrop })
    cropObject.eventObjectMoving(can,e.target,imgObject)
  }

  const eveObjectScaling=(e)=>{
    cropObject.eventObjectScaling(can,e.target)
  }

  const eveSelectionCleared=(e)=>{ 

    if(e.deselected)
    {
    cropObject.eventSelectionCleared(can,e.deselected[0])
    }

  }

  const eveSelectionCreated=(event)=>{
    checkCropStatus(event.selected)

  }
  const eveSelectionUpdated=(event)=>{
    checkCropStatus(event.selected[0])
  cropObject.eventSelectionUpdated(can, event.selected[0], event.deselected[0])
  }

  const eveObjectAdded=(e)=>{
    let target = e?.target
    if (target?.type === 'image') {
        target.on('mousedblclick', (e) => {
          can.bringToFront(e.target);
          cropObject?.customInitCrop(can, e.target)
        })
     
    }
 }


  const addImage = () => {


  //   var image = new Image();
                   
  //   image.src = url;
  //   image.crossOrigin='anonymous'
  //   image.crossDomain=true

  //   image.onload =  ()=> {
  //       var img = new fabric.Image(image);
  //       let scale = 300 / img.width;
  //       img.crossOrigin="anonymous"
  //      img.set({
  //         left: 20,
  //         top: 20,
  //         scaleX: scale,
  //         scaleY: scale,
  
  //       });
  //       can.add(img);
  //       can.renderAll()
  // }

  fabric.Image.fromURL(Cat, function (oImg) {
    let scale = 200 / oImg.width;
   oImg.set({
      left: 20,
      top: 20,
      scaleX: scale,
      scaleY: scale,
      name:"cat",
      cornerStyle:'circle',
    });

    can.add(oImg); 
    can.renderAll();
}, { crossOrigin:'anonymous' });
}
  return (

    <div className="App" >
     <div style={{ 
    marginTop:"10%",}}>
      <h3>Double tap to crop</h3>
      <div style={{marginLeft:"32%"}}>
      <canvas
        width="500"
        height="400"
        id="canvasElement"
        style={{
        
          border: '1px gray solid',
        }}
      />
      </div>
      <div style={{marginTop:"10px"}}>
              <button style={{marginLeft:"10px"}} onClick={addImage}>Add Image</button>
      

          <button style={{marginLeft:"10px"}} onClick={()=>{
            const shapeImgRef = document.getElementById('62b5813c97724ed4254fb295')
            let img=can.getActiveObject();
            cropObject.cropShapeImg(can, shapeImgRef,img)

          }}>
          <img title="oval" alt="img" data-url="https://d2p8v0fajz53hs.cloudfront.net/image/original/assets/crop/oval.svg" id="62b5813c97724ed4254fb295" src="https://d2p8v0fajz53hs.cloudfront.net/image/original/assets/crop/oval.png"/>

          </button>
      

       {/* <button style={{marginLeft:"10px"}} onClick={()=>{
            cropObject?.btnEditCrop(can,true)
          }}> Crop</button> */}
     

          <button style={{marginLeft:"10px"}} onClick={()=>{
            cropObject?.btnCropDone(can,false)
          }}> Done</button>

       <button style={{marginLeft:"10px"}} onClick={()=>{
            cropObject?.btnCancelCrop(can,false)
          }}> Cancel</button>
       

       <button style={{marginLeft:"10px"}} onClick={()=>{
            cropObject?.btnClearCrop(can)
          }}> Clear Crop</button>

       <button style={{marginLeft:"10px"}} onClick={()=>{
            let obj=can.getActiveObject();
             can.remove(obj);
          }}> Delete </button>

<button style={{marginLeft:"10px"}} onClick={()=>{
let obj=can.getActiveObject();
let cloneObject=fabric.util.object.clone(obj)
cloneObject.set({left:cloneObject.left+10,
top:cloneObject.top+10
})
can.add(cloneObject)
can.renderAll()

}}>Clone</button>



          </div>
      
      </div>

    </div>
  );
}

export default App
