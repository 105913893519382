import {fabric} from 'fabric'

/* Crop */
let currentImage
let isNewCrop = null
let isCropping = false
let cropObject
let currentObject
let isShapeCrop = false
let clickedShape
let cropRect
let cropLightRect

export class UploadCropOptionDispatcher {
     selectedCanvas = null
     selectedCanvasObject

    constructor(
        selectedCanvas =null,
    ) {
        this.selectedCanvas = selectedCanvas ? selectedCanvas : null
        this.selectedCanvasObject = this.selectedCanvas?.getActiveObject()
    }


    eventObjectModified(canvas,object) {
        this.selectedCanvas=canvas
        this.selectedCanvasObject = this.selectedCanvas?.getActiveObject()
        if (
            this.selectedCanvasObject &&
            this.selectedCanvasObject.objType === 'shape' &&
            this.selectedCanvasObject.type === 'rect' &&
            (this.selectedCanvasObject.rx > 0 ||
                this.selectedCanvasObject.ry > 0)
        ) {
            this.selectedCanvasObject.set({
                height: this.selectedCanvasObject.getScaledHeight(),
                scaleX: 1,
                scaleY: 1,
                width: this.selectedCanvasObject.getScaledWidth(),
            })
            this.selectedCanvasObject.setCoords()
            this.selectedCanvas?.requestRenderAll()
        }

        if (object.custtype === 'picArea') {
            /* Check the crop object do not cross the image */
            if (object.left < currentObject.left) {
                object.left = currentObject.left
                object.setCoords()
                if (
                    object.left + object.getScaledWidth() >
                    currentObject.left + currentObject.getScaledWidth()
                ) {
                    object.width = currentObject.getScaledWidth()
                    object.scaleX = 1
                    object.setCoords()
                }
            }
            if (object.top < currentObject.top) {
                object.top = currentObject.top
                object.setCoords()
                if (
                    object.top + object.getScaledHeight() >
                    currentObject.top + currentObject.getScaledHeight()
                ) {
                    object.height = currentObject.getScaledHeight()
                    object.scaleY = 1
                    object.setCoords()
                }
            }
            if (
                object.left >
                currentObject.left +
                    (currentObject.getScaledWidth() - object.getScaledWidth())
            ) {
                if (object.getScaledWidth() > currentObject.getScaledWidth()) {
                    object.left = currentObject.left
                    object.width = currentObject.getScaledWidth()
                    object.scaleX = 1
                } else {
                    object.left =
                        currentObject.left +
                        (currentObject.getScaledWidth() -
                            object.getScaledWidth())
                }
                object.setCoords()
            }
            if (
                object.top >
                currentObject.top +
                    (currentObject.getScaledHeight() - object.getScaledHeight())
            ) {
                if (
                    object.getScaledHeight() > currentObject.getScaledHeight()
                ) {
                    object.top = currentObject.top
                    object.height = currentObject.getScaledHeight()
                    object.scaleY = 1
                } else {
                    object.top =
                        currentObject.top +
                        (currentObject.getScaledHeight() -
                            object.getScaledHeight())
                }
                object.setCoords()
            }
        }
    }

    eventObjectMoving(canvas, object, imgObj) {

        this.selectedCanvas = canvas
        this.selectedCanvasObject = canvas.getActiveObject()
        if (
            this.selectedCanvasObject &&
            this.selectedCanvasObject.picArea &&
            object.custtype !== 'picArea'
        ) {
            if (
                this.selectedCanvasObject.left +
                    this.selectedCanvasObject.width <
                this.selectedCanvasObject.picArea.left +
                    this.selectedCanvasObject.picArea.width / 2
            ) {
                this.selectedCanvasObject.left =
                    this.selectedCanvasObject.picArea.left +
                    this.selectedCanvasObject.picArea.width / 2 -
                    this.selectedCanvasObject.width
            }
            if (
                this.selectedCanvasObject.left >
                this.selectedCanvasObject.picArea.left +
                    this.selectedCanvasObject.picArea.width / 2
            ) {
                this.selectedCanvasObject.left =
                    this.selectedCanvasObject.picArea.left +
                    this.selectedCanvasObject.picArea.width / 2
            }
            if (
                this.selectedCanvasObject.top +
                    this.selectedCanvasObject.height <
                this.selectedCanvasObject.picArea.top +
                    this.selectedCanvasObject.picArea.height / 2
            ) {
                this.selectedCanvasObject.top =
                    this.selectedCanvasObject.picArea.top +
                    this.selectedCanvasObject.picArea.height / 2 -
                    this.selectedCanvasObject.height
            }
            if (
                this.selectedCanvasObject.top >
                this.selectedCanvasObject.picArea.top +
                    this.selectedCanvasObject.picArea.height / 2
            ) {
                this.selectedCanvasObject.top =
                    this.selectedCanvasObject.picArea.top +
                    this.selectedCanvasObject.picArea.height / 2
            }
        } else if (object.custtype == 'picArea') {
            if (object.left < this.selectedCanvasObject.left) {
                object.left = this.selectedCanvasObject.left
            }
            if (object.top < this.selectedCanvasObject.top) {
                object.top = this.selectedCanvasObject.top
            }
            if (
                object.left >
                this.selectedCanvasObject.left +
                    (this.selectedCanvasObject.getScaledWidth() -
                        object.getScaledWidth())
            ) {
                object.left =
                    this.selectedCanvasObject.left +
                    (this.selectedCanvasObject.getScaledWidth() -
                        object.getScaledWidth())
            }
            if (
                object.top >
                this.selectedCanvasObject.top +
                    (this.selectedCanvasObject.getScaledHeight() -
                        object.getScaledHeight())
            ) {
                object.top =
                    this.selectedCanvasObject.top +
                    (this.selectedCanvasObject.getScaledHeight() -
                        object.getScaledHeight())
            }
        }
        this.selectedCanvas?.requestRenderAll()
        isCropping && this.setCropBlur(this.selectedCanvas)

    }

    eventObjectScaling(canvas, object) {

        this.selectedCanvas = canvas
        if (object && object.custtype) {
            object.width *= object.scaleX
            object.height *= object.scaleY
            object.scaleX = object.scaleY = 1
            object.setCoords()
        }
        if (object && object.type === 'image' && object.isNewCrop) {
            object.oldScaleX = object.scaleX
            object.oldScaleY = object.scaleY
        }
        if (object.custtype === 'picArea') {
            object.dirty = true
            object.setCoords()
        }
        !isNewCrop && this.setCropBlur(this.selectedCanvas)

    }

    eventSelectionUpdated(
        canvas,
        object,
        selectedCanvasObject,
    ) {
       
        this.selectedCanvas = canvas
        if (object && object.custtype ==='picArea') {
            selectedCanvasObject.picArea = object
        } else {
            isCropping &&
                object &&
                object.objID !== 'cropRect' &&
                this.btnCropDone(this.selectedCanvas,false)
        }
    }

    eventSelectionCleared(canvas,cropRect) {
             
         this.btnCropDone(canvas,false,cropRect)
    }

    btnCropDone(canvas,call,cropRect) {
        this.selectedCanvas = canvas
        isNewCrop
            ? this.customCropImage(this.selectedCanvas,cropRect)
            : this.cropImage(this.selectedCanvas,cropRect)

           if(call!==null){
            isCropping=call;
            }
    
    }

    btnEditCrop(canvas,call,newCall){
        if(call!==null){
            isCropping=call;
            }
      
        let object=canvas.getActiveObject()
        this.selectedCanvas = canvas
        if (object.isNewCrop) {
            this.customInitCrop(this.selectedCanvas, object)
        } else {
            isShapeCrop && clickedShape
                ? this.cropShapeImg(this.selectedCanvas, clickedShape)
                : this.initCrop(this.selectedCanvas)
        }
 
    }

    btnCancelCrop(canvas,call) {
     
        this.selectedCanvas = canvas
        this.removeCropElements(this.selectedCanvas, true)
        ;
        isShapeCrop = false;
        clickedShape = null
      
    }

    btnClearCrop(canvas) {
        this.selectedCanvas = canvas
        isCropping
            ? this.removeCropElements(this.selectedCanvas, true, true)
            : this.removeCrop(canvas)
        cropObject && this.selectedCanvas?.setActiveObject(cropObject);
        
        isShapeCrop = false;
        clickedShape = null;
    }

    cropShapeImg(canvas, shapeImgRef,cruntimg) {
        let currentImage1=cruntimg;
        this.selectedCanvas = canvas
        isCropping && this.removeCropElements(canvas, false, true)
        if (currentImage1) {
            currentImage1.picAreaScale && (currentImage1.picAreaScale = null)
           if(currentImage1.isNewCrop===true){
            this.btnClearCrop(canvas)
           } 
        }
        let dataUrl = 'https://d2p8v0fajz53hs.cloudfront.net/image/original/assets/crop/oval.svg'
        const imgUrl = dataUrl; // shapeImgRef.getAttribute('data-url')
        fabric.loadSVGFromURL(imgUrl, (objects, options) => {
            const img = fabric.util.groupSVGElements(objects, options, imgUrl)
            img.set({objType: 'crop', objects: imgUrl, sourcePath: imgUrl,originX:'center',originY:'center'})
            this.initCrop(canvas, img,null,currentImage1)
        });

        isShapeCrop = true;
         clickedShape = shapeImgRef;

    }

    initCrop(canvas, clipObj, isCrop,currentImage) {
        this.selectedCanvas = canvas

        currentObject = currentImage

        this.selectedCanvas.bringToFront(currentObject)


        cropObject = cropObject || currentObject
        if (currentObject.cropX || currentObject.cropY) {
            currentObject.scaleX = currentObject.oldScaleX;

                currentObject.scaleY = currentObject.oldScaleY;

            currentObject.width = currentObject._element.width;

            currentObject.height = currentObject._element.height;
            currentObject.cropX = currentObject.cropY = 0
            currentObject.setCoords()
        }
        if (currentObject.originX !== 'center') {
            currentObject.left += currentObject.getScaledWidth() / 2
            currentObject.top += currentObject.getScaledHeight() / 2
            currentObject.originX = currentObject.originY = 'center'
        } else {
            currentObject.oldLeft = currentObject.left
            currentObject.oldTop = currentObject.top
        }
        if(currentObject.isNewCrop){
            isCropping
                ? this.removeCropElements(this.selectedCanvas)
                : this.removeCrop(this.selectedCanvas);

        }
        cropRect && this.selectedCanvas?.remove(cropRect)
        cropLightRect && this.selectedCanvas?.remove(cropLightRect)
        let left, top, width, height, clipObjOverlay
        if (
            !currentObject ||
            (!clipObj && !isCropping && currentObject.objID !== 'cropRect')
        ) {
            console.log('return true')
        } else {
            if (
                !clipObj &&
                cropObject.clipPath &&
                (!isCrop || cropObject.clipPath.type === 'rects')
            ) {
                if (cropObject.picAreaScale) {
                    width = cropObject.picAreaScale.width;
                    height = cropObject.picAreaScale.height;
                    cropObject.setCoords()
                }
                left =
                    cropObject.left +
                    cropObject.clipPath.left * cropObject.scaleX;

                top =cropObject.top +
                        cropObject.clipPath.top * cropObject.scaleY;
                clipObj = cropObject.clipPath
            } else {
                left = cropObject.left;
                top = cropObject.top;
                width = cropObject.getScaledWidth();
                height = cropObject.getScaledHeight();
                cropObject.setCoords()
            }
            if(cropObject){
                cropObject.clipPath = null;
                cropObject.dirty = true;
                }


            cropObject = cropObject =
                cropObject ||
                cropObject ||
                this.selectedCanvas?.getActiveObject()
            // cropObject.set({angle: 0, selectable: false})
        }

        if (cropObject) {
            if (clipObj) {
                clipObjOverlay = fabric.util.object.clone(clipObj)
                const scaleX = width / clipObj.getScaledWidth(),
                    scaleY = height / clipObj.getScaledHeight(),
                    scale = scaleX > scaleY ? scaleY : scaleX
                clipObj.set({
                    angle: cropObject.angle,
                    excludeFromExport: true,
                    fill: 'rgba(0,0,0,0)',
                    hasRotatingPoint: false,
                    inverted: true,
                    left: left,
                    objID: 'cropRect',
                    objType: 'crop',
                    objectCaching: false,
                    originX: cropObject.originX,
                    originY: cropObject.originY,
                    scaleX: clipObj.scaleX * scale,
                    scaleY: clipObj.scaleY * scale,
                    selectable: true,
                    top: top,
                })
                clipObj.setCoords()
                cropRect = clipObj
            } else {
                cropRect = new fabric.Rect({
                    angle: cropObject.angle,
                    excludeFromExport: true,
                    fill: 'rgba(0,0,0,0)',
                    hasRotatingPoint: false,
                    height: height,
                    inverted: true,
                    left: left,
                    objID: 'cropRect',
                    objType: 'crop',
                    objectCaching: false,
                    originX: cropObject.originX,
                    originY: cropObject.originY,
                    selectable: true,
                    strokWidth: 1,
                    stroke: '#ccc',
                    top: top,
                    width: width,
                })

                cropRect.setCoords()
            }
            cropLightRect = new fabric.Rect({
                angle: cropObject.angle,
                excludeFromExport: true,
                fill: 'rgba(0,0,0,0.5)',
                hasRotatingPoint: false,
                height: cropObject.getScaledHeight(),
                left: cropObject.left,
                objID: 'lightRect',
                objType: 'crop',
                objectCaching: false,
                originX: cropObject.originX,
                originY: cropObject.originY,
                selectable: false,
                top: cropObject.top,
                width: cropObject.getScaledWidth(),
            })
            if (clipObj && clipObjOverlay) {
                clipObjOverlay.set({
                    excludeFromExport: true,
                    cornerStyle:'circle',
                    fill: 'rgba(0,0,0,1)',
                    inverted: true,
                    left: left - cropLightRect.left,
                    objID: 'cropRect',
                    objType: 'crop',
                    objectCaching: false,
                    originX: cropObject.originX,
                    originY: cropObject.originY,
                    scaleX: clipObj.scaleX,
                    scaleY: clipObj.scaleY,
                    selectable: false,
                    top: top - cropLightRect.top,
                })
                cropLightRect.clipPath = clipObjOverlay
            } else {
                cropLightRect.clipPath = new fabric.Rect({
                    fill: 'rgba(0,0,0,1)',
                    height: height,
                    inverted: true,
                    left: left - cropLightRect.left,
                    objType: 'crop',
                    originX: cropObject.originX,
                    originY: cropObject.originY,
                    top: top - cropLightRect.top,
                    width: width,
                })
            }
            this.selectedCanvas?.add(cropLightRect)
            this.selectedCanvas?.add(cropRect)
            this.selectedCanvas?.setActiveObject(cropRect)
            this.selectedCanvas?.renderAll()
            this.selectedCanvasObject = cropRect
            isCropping = true
        }
    }

    customInitCrop(canvas, object,newCall) {
   
        this.selectedCanvas = canvas
        currentImage = object
        currentObject = object; // added this to restrict shape inside 
        if (!isNewCrop && object.clipPath) {
            isCropping
                ? this.removeCropElements(this.selectedCanvas, true, true)
                : this.removeCrop(this.selectedCanvas)
            cropObject && this.selectedCanvas?.setActiveObject(cropObject)
        }
        if (object && object.type === 'image' && !object.picArea) {
            object.oldOpacity = object.opacity
            object.oldOriginX = object.originX
            object.oldOriginY = object.originY
            this.resetCustomCropImage(object)
            this.captureOldObjectValues(object)
            object.picArea.opacity = 0.5
            object.hasRotatingPoint = true
            object.setControlVisible('mtr', true)
            this.selectedCanvas?.bringToFront(object.picArea)
            this.selectedCanvas
                ?.setActiveObject(object.picArea)
                .requestRenderAll()
            isNewCrop = object.isNewCrop = true
            isCropping = true
        }
   
    }

    resetCustomCropImage(object) {
        if (object.angle) {
            let isCentered = false
            if (object.originX !== 'center') {
                object._originalOriginX = object.originX
                object._originalOriginY = object.originY
                const center = object.getCenterPoint()
                object.originX = object.originY = 'center'
                object.left = center.x
                object.top = center.y
                isCentered = true
            }
            object.angle = 0
            object.rotate(0)
            if (isCentered) {
                const originPoint = object.translateToOriginPoint(
                    object.getCenterPoint(),
                    object._originalOriginX,
                    object._originalOriginY,
                )
                object.originX = object._originalOriginX
                object.originY = object._originalOriginY
                object.left = originPoint.x
                object.top = originPoint.y
                object._originalOriginX = object._originalOriginY = null
            }
        }
        if (object.originX === 'center') {
            object.oldLeft = object.left;
            object.oldTop = object.top;
            object.originX = 'left'
            object.originY = 'top'
            object.setCoords()
            object.left -= (object.width * object.scaleX) / 2
            object.top -= (object.height * object.scaleY) / 2
            object.setCoords()
        }
        this.addPictureArea(object)
        object.hasRotatingPoint = false
        object.setControlVisible('mtr', false)
        /* Start:: Check PicArea cross the image */
        if (object.picArea.left < object.left) {
            object.picArea.left = object.left
            object.picArea.setCoords()
        }
        if (object.picArea.top < object.top) {
            object.picArea.top = object.top
            object.picArea.setCoords()
        }
        if (
            object.picArea.left + object.picArea.getScaledWidth() >
                object.left + object.getScaledWidth() ||
            object.picArea.getScaledWidth() > object.getScaledWidth()
        ) {
            object.picArea.width = object.getScaledWidth()
            object.picArea.scaleX = 1
            object.picArea.left = object.left
            object.picArea.setCoords()
        }
        if (
            object.picArea.top + object.picArea.getScaledHeight() >
                object.top + object.getScaledHeight() ||
            object.picArea.getScaledHeight() > object.getScaledHeight()
        ) {
            object.picArea.height = object.getScaledHeight()
            object.picArea.scaleY = 1
            object.picArea.top = object.top
            object.picArea.setCoords()
        }
        this.selectedCanvas?.renderAll()
        /* End:: Check PicArea cross the image */
        if (object.cropX || object.cropY) {
            const oldLeft = object.picArea.left
            const oldTop = object.picArea.top
            if (object.cropX)
                object.left = object.picArea.left - object.cropX * object.scaleX
            if (object.cropY)
                object.top = object.picArea.top - object.cropY * object.scaleY
            object.scaleX = object.oldScaleX
            object.scaleY = object.oldScaleY
            object.width = object._element.width
            object.height = object._element.height
            object.cropX = 0
            object.cropY = 0
            object.setCoords()
            object.picArea.dirty = true
            object.picArea.left = oldLeft
            object.picArea.top = oldTop
            object.picArea.setCoords()
        } else {
            if (object.width < object._element.width)
                object.width = object._element.width
            if (object.height < object._element.height)
                object.height = object._element.height
            object.setCoords()
            object.picArea.setCoords()
        }
    }

    captureOldObjectValues(object) {
        object.oldWidth = object.width;
         object.oldHeight = object.height;

        object.oldLeft = object.left;
         object.oldTop = object.top;

        object.oldScaleX = object.scaleX;
         object.oldScaleY = object.scaleY;

        object.oldOriginX = object.originX;

        object.oldOriginY = object.originY;

        object.oldAngle = object.angle;
        object.oldOpacity = object.opacity;
    }
    

    addPictureArea(picture) {

      
      

        const picArea = new fabric.Rect({
            angle: picture.angle,
            excludeFromExport: true,
            fill: '0,0,0',
            hasRotatingPoint: true,
            height: picture.getScaledHeight(),
            left: picture.left,
            originX: picture.originX,
            originY: picture.originY,
            cornerColor:"blue",
            selectable: true,
            top: picture.top,
            width: picture.getScaledWidth(),
        })

    

    
        picArea.custtype = 'picArea'
        this.selectedCanvas?.add(picArea)
        picArea.setCoords()
        const picIndex = this.selectedCanvas?.getObjects().indexOf(picture)
        if (!picIndex) {
            picArea.moveTo(0)
            picture.moveTo(1)
        } else {
            picArea.moveTo(picIndex)
        }
        picture.picArea = picArea

 
        this.selectedCanvas?.renderAll()
    }

    customCropImage(canvas,cropRect) {
        this.selectedCanvas = canvas
        const currentObject = currentImage
        if (
            currentObject &&
            currentObject.type === 'image' &&
            currentObject.picArea
        ) {
            isNewCrop = false
            isCropping = false
            this.cropActualImage(this.selectedCanvas,cropRect)
        }
    }

    
    cropActualImage(canvas, cropObject) {
        this.selectedCanvas = canvas
        const currentObject =
            currentImage || this.selectedCanvas?.getActiveObject()
        cropObject =
            cropObject || (currentObject.picArea ? currentObject.picArea : null)
        const cropObjectBoundingRect = cropObject.getBoundingRect()
        if (
            currentObject.width * currentObject.scaleX <
                cropObject.width * cropObject.scaleX &&
            currentObject.height * currentObject.scaleY <
                cropObject.height * cropObject.scaleY
        ) {
            console.log('TBD')
        } else {
            const currentObjectBoundingRect = currentObject.getBoundingRect()
            //functionality to crop
            if (
                currentObjectBoundingRect.top > cropObjectBoundingRect.top &&
                currentObjectBoundingRect.left > cropObjectBoundingRect.left
            ) {
                const dt =
                    currentObjectBoundingRect.top - cropObjectBoundingRect.top
                const dl =
                    currentObjectBoundingRect.left - cropObjectBoundingRect.left
                // top left
                cropObject.top = currentObjectBoundingRect.top
                cropObject.left = currentObjectBoundingRect.left
                // height width
                cropObject.height -= dt
                cropObject.width -= dl
            } else if (
                currentObjectBoundingRect.top > cropObjectBoundingRect.top &&
                currentObjectBoundingRect.left < cropObjectBoundingRect.left
            ) {
                const dt =
                    currentObjectBoundingRect.top - cropObjectBoundingRect.top
               
                // top left
                cropObject.top = currentObjectBoundingRect.top
                // height width
                cropObject.height -= dt
                if (
                    currentObjectBoundingRect.left +
                        currentObjectBoundingRect.width <
                    cropObjectBoundingRect.left + cropObjectBoundingRect.width
                )
                    cropObject.width = Math.abs(
                        currentObjectBoundingRect.left +
                            currentObjectBoundingRect.width -
                            cropObjectBoundingRect.left,
                    )
            } else if (
                currentObjectBoundingRect.top > cropObjectBoundingRect.top &&
                currentObjectBoundingRect.left === cropObjectBoundingRect.left
            ) {
                cropObject.height -=
                    currentObjectBoundingRect.top - cropObjectBoundingRect.top
                cropObject.top = currentObjectBoundingRect.top
            } else if (
                currentObjectBoundingRect.top === cropObjectBoundingRect.top
            ) {
                if (
                    currentObjectBoundingRect.left < cropObjectBoundingRect.left
                ) {
                    console.log('TBD')
                } else {
                    cropObject.width -=
                        currentObjectBoundingRect.left -
                        cropObjectBoundingRect.left
                    cropObject.left = currentObjectBoundingRect.left
                }
            } else if (
                currentObjectBoundingRect.top < cropObjectBoundingRect.top &&
                currentObjectBoundingRect.left < cropObjectBoundingRect.left
            ) {
                const dt =
                    currentObjectBoundingRect.height +
                    currentObjectBoundingRect.top -
                    cropObjectBoundingRect.top
                const dl =
                    currentObjectBoundingRect.width +
                    currentObjectBoundingRect.left -
                    cropObjectBoundingRect.left
            } else if (
                currentObjectBoundingRect.top < cropObjectBoundingRect.top &&
                currentObjectBoundingRect.left === cropObjectBoundingRect.left
            ) {
                if (currentObjectBoundingRect.top < 0) {
                    if (
                        currentObjectBoundingRect.height <
                            cropObjectBoundingRect.height ||
                        currentObjectBoundingRect.top +
                            currentObjectBoundingRect.height <
                            cropObjectBoundingRect.height +
                                cropObjectBoundingRect.top
                    ) {
                        cropObject.height =
                            currentObjectBoundingRect.height +
                            currentObjectBoundingRect.top -
                            cropObjectBoundingRect.top
                    }
                } else if (
                    currentObjectBoundingRect.height <
                        cropObjectBoundingRect.height ||
                    currentObjectBoundingRect.top +
                        currentObjectBoundingRect.height <
                        cropObjectBoundingRect.height +
                            cropObjectBoundingRect.top
                ) {
                    cropObject.height =
                        currentObjectBoundingRect.height -
                        (cropObjectBoundingRect.top -
                            currentObjectBoundingRect.top)
                }
            } else if (
                currentObjectBoundingRect.top < cropObjectBoundingRect.top &&
                currentObjectBoundingRect.left > cropObjectBoundingRect.left
            ) {
                const dt =
                    currentObjectBoundingRect.height +
                    currentObjectBoundingRect.top -
                    cropObjectBoundingRect.top
                const dl =
                    currentObjectBoundingRect.left - cropObjectBoundingRect.left
                // left
                cropObject.left = currentObjectBoundingRect.left
                // height width
                if (
                    currentObjectBoundingRect.height +
                        currentObjectBoundingRect.top <
                    cropObjectBoundingRect.height + cropObjectBoundingRect.top
                ){
                    cropObject.height = dt;
                    cropObject.width -= dl;
                }
            }
            //scale object
            currentObject.height =
                cropObjectBoundingRect.height / currentObject.scaleY
            currentObject.width =
                cropObjectBoundingRect.width / currentObject.scaleX
            currentObject.oldScaleX = currentObject.scaleX
            currentObject.oldScaleY = currentObject.scaleY
            //crop
            currentObject.cropX =
                (cropObjectBoundingRect.left - currentObjectBoundingRect.left) /
                currentObject.scaleX
            currentObject.cropY =
                (cropObjectBoundingRect.top - currentObjectBoundingRect.top) /
                currentObject.scaleY
            currentObject.left = cropObjectBoundingRect.left
            currentObject.top = cropObjectBoundingRect.top
        }
        currentObject.setCoords()
        currentObject.selectable = true
        currentObject.hasRotatingPoint = true
        currentObject.setControlVisible('mtr', true)
        currentObject.picAreaScale = {
            height: cropObject.getScaledHeight(),
            left: cropObjectBoundingRect.left,
            scaleX: cropObject.scaleX,
            scaleY: cropObject.scaleY,
            top: cropObjectBoundingRect.top,
            width: cropObject.getScaledWidth(),
        }
        this.selectedCanvas?.remove(cropObject)
        currentObject.isNewCrop && (currentObject.picArea = null)
        this.selectedCanvas?.discardActiveObject().requestRenderAll()
    }

    cropImage(canvas,cropRect) {
        this.selectedCanvas = canvas
        if (!isCropping) {
            return
        } else if (!cropObject) {
            return
        } else {
            const lightRect = this.getObjectById(
                this.selectedCanvas,
                'lightRect',
            )
            if (lightRect && lightRect.clipPath) {
                cropObject.oldScaleX = lightRect.clipPath.scaleX
                cropObject.oldScaleY = lightRect.clipPath.scaleY
                const clippath = fabric.util.object.clone(lightRect.clipPath)
                cropObject.picAreaScale = {
                    height: clippath.getScaledHeight(),
                    left: clippath.left,
                    scaleX: clippath.scaleX,
                    scaleY: clippath.scaleY,
                    top: clippath.top,
                    width: clippath.getScaledWidth(),
                }
                clippath.set({
                    inverted: false,
                    left:
                        (clippath.left * lightRect.scaleX) / cropObject.scaleX,
                    scaleX:
                        (clippath.scaleX * lightRect.scaleX) /
                        cropObject.scaleX,
                    scaleY:
                        (clippath.scaleY * lightRect.scaleY) /
                        cropObject.scaleY,

                    top: (clippath.top * lightRect.scaleY) / cropObject.scaleY,
                })
                cropObject.clipPath = clippath
            }
            cropObject.clipPath.dirty = true
            cropObject.set({
                dirty: true,
                perPixelTargetFind: true,
                selectable: true,
            })
            cropObject.filters && (cropObject.filters.length = 0)
            cropObject.setCoords()
            this.selectedCanvas?.requestRenderAll()
        }

        this.removeCropElements(this.selectedCanvas,null,null,cropRect)
    }

    setCropBlur(canvas) {
        this.selectedCanvas = canvas
        const object = this.selectedCanvas?.getActiveObject()
        if (object && object.objID === 'cropRect') {
            cropLightRect.clipPath.set({
                dirty: true,
                left: object.left - cropLightRect.left,
                top: object.top - cropLightRect.top,
            })
            if (cropLightRect.clipPath.type === 'rect') {
                const wCP =
                    (object.getScaledWidth() * cropLightRect.clipPath.width) /
                    cropLightRect.clipPath.getScaledWidth()
                const hCP =
                    (object.getScaledHeight() * cropLightRect.clipPath.height) /
                    cropLightRect.clipPath.getScaledHeight()
                cropLightRect.clipPath.set({height: hCP, width: wCP})
            } else {
                cropLightRect.clipPath.set({
                    scaleX: object.scaleX,
                    scaleY: object.scaleY,
                })
            }
            cropLightRect.dirty = true
            this.selectedCanvas?.requestRenderAll()
        }
    }

    removeCropElements(
        canvas,
        discardCrop,
        isChangeShape,
        cropRect
    ) {

        
        this.selectedCanvas = canvas
        currentObject = canvas?.getActiveObject()||cropRect||currentImage

        if (
            (isCropping && currentObject && !currentObject.isNewCrop) ||
            (currentObject &&
                currentObject.objType === 'uploaded' &&
                currentObject.clipPath)
        ) {
            isCropping = false
            const rectObj = this.getObjectById(canvas, 'cropRect')
            const lightRect = this.getObjectById(
                canvas,
                'lightRect',
            )
            rectObj && this.deletedata(rectObj)
            lightRect && this.deletedata(lightRect)
            discardCrop &&
                this.removeCrop(this.selectedCanvas,cropObject);
                
                currentObject.picAreaScale &&
                    (currentObject.picAreaScale = null);

            cropRect = null
            cropObject && cropObject.set({selectable: true})
        }
        if (isCropping && currentObject.isNewCrop) {
            currentObject.cropX = currentObject.cropY = 0
            isCropping = isNewCrop = currentObject.isNewCrop = false
            currentObject.isNewCrop=false;
            currentObject.width = currentObject._element.width
            currentObject.height = currentObject._element.height
            currentObject.selectable = true
            currentObject.hasRotatingPoint = true
            if(currentObject.picArea){
                this.deletedata(currentObject.picArea);
                currentObject.picArea = null
            }
                
            currentObject.picAreaScale && (currentObject.picAreaScale = null);

            if(cropRect){
                this.deletedata(cropRect)
                 cropRect = null
            }

            if(cropLightRect){
                this.deletedata(cropLightRect);
                cropLightRect = null;
            }
                
            currentObject.setControlVisible('mtr', true)
            canvas?.renderAll()
        }
        cropObject && cropObject.set({selectable: true})
        ;(isChangeShape && !currentObject.isNewCrop) || (cropObject = null)
    }

    removeCrop(canvas, obj) {

        this.selectedCanvas = canvas
        const currentObject = canvas?.getActiveObject()||obj
        if (currentObject.isNewCrop===true) {

            this.resetCustomCropImage(currentObject)
            isCropping=false 
            isNewCrop=false
            currentObject.isNewCrop=false
            currentObject.width = currentObject._element.width
            currentObject.height = currentObject._element.height
            this.captureOldObjectValues(currentObject)
            currentObject.selectable = true
            currentObject.hasRotatingPoint = true
            currentObject.setControlVisible('mtr', true)
            if(currentObject.picArea){
                this.deletedata(currentObject.picArea);
                currentObject.picArea = null;
            }
            currentObject.picAreaScale && (currentObject.picAreaScale = null)

            if(cropRect){
                this.deletedata(cropRect);
                cropRect = null;
            }  

            if(cropLightRect){
                this.deletedata(cropLightRect);
                cropLightRect = null;

            }
            this.selectedCanvas?.renderAll()

        } else {
            currentObject?.set({
                clipPath: null,
                perPixelTargetFind: false,
                selectable: true,
            })

        }
        this.selectedCanvas?.renderAll()
        return true
    }

    getObjectById(canvas, id) {
        let object = null
        const objects = canvas?.getObjects()
        for (let i = 0, len = objects.length; i < len; i++) {
            if (objects[i]['objID'] && objects[i]['objID'] === id) {
                object = objects[i]
                break
            }
        }
        return object
    }

    deletedata(object) {
        let activeObj = object || this.selectedCanvas?.getActiveObject()
        activeObj.objID === 'cropRect' &&
            isCropping &&
            (activeObj = currentImage)
        if (activeObj) {
            this.selectedCanvas?.remove(activeObj)
            this.selectedCanvas?.discardActiveObject().renderAll()
        }
    }
}
